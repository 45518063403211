import { useEffect } from 'react';
import { navigate } from 'gatsby';
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return '';
  }
  const lang = navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) {
    return 'tw';
  }
  switch (lang) {
    case 'zh': {
      const chinese = navigator.language.split('-')[1];
      return chinese === 'CN' ? 'cn' : 'tw';
    }
    case 'en':
      return 'en';
    default:
      return 'tw';
  }
};
export default () => {
  useEffect(() => {
    navigate(`/${getRedirectLanguage()}`);
  }, []);
  return null;
};
